import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const DOLAR_API_URL = process.env.REACT_APP_DOLAR_API_URL;
const COTIZACIONES_STORAGE_KEY = 'cotizaciones_cache';
const CACHE_DURATION = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

const api = axios.create({
  baseURL: API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

export const fetchCotizaciones = async () => {
  try {
    const storedData = localStorage.getItem(COTIZACIONES_STORAGE_KEY);
    if (storedData) {
      const { data, timestamp } = JSON.parse(storedData);
      const now = new Date().getTime();
      if (now - timestamp < CACHE_DURATION) {
        return data;
      }
    }

    const response = await api.get('/cotizaciones/');
    const data = response.data;
    localStorage.setItem(COTIZACIONES_STORAGE_KEY, JSON.stringify({ data, timestamp: new Date().getTime() }));
    return data;
  } catch (error) {
    console.error('Error fetching cotizaciones:', error);
    throw error;
  }
};

export const fetchDolarData = async () => {
  try {
    const response = await axios.get(DOLAR_API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching dolar data:', error);
    throw error;
  }
};

export const saveTransaction = async (type, data) => {
  try {
    const response = await api.post(`/transactions/${type}/`, data);
    return response.data;
  } catch (error) {
    console.error(`Error saving ${type} transaction:`, error);
    throw error;
  }
};

export const getTransactions = async (type) => {
  try {
    const response = await api.get(`/transactions/${type}/`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching ${type} transactions:`, error);
    throw error;
  }
};

export const deleteTransaction = async (type, id) => {
  try {
    await api.delete(`/transactions/${type}/${id}/`);
  } catch (error) {
    console.error(`Error deleting ${type} transaction:`, error);
    throw error;
  }
};

export default api;