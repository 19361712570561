import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CotizacionesProvider } from './context/CotizacionesContext';
import Layout from './components/Layout';

const DashboardContent = lazy(() => import('./components/DashboardContent'));
const Tenencias = lazy(() => import('./pages/Tenencias'));

function App() {
  return (
    <CotizacionesProvider>
      <Router>
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Navigate to="/cotizaciones" replace />} />
              <Route path="/cotizaciones" element={<DashboardContent />} />
              <Route path="/tenencias" element={<Tenencias />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </CotizacionesProvider>
  );
}

export default App;